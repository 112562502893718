import consumer from "./consumer";

document.addEventListener('DOMContentLoaded', function () {
  const authBtn = document.getElementById('twitter-auth-btn');

  if (!authBtn) {
    console.warn('Twitter auth button not found. Ensure the element ID is correct.');
    return;
  }

  const token = authBtn.getAttribute('data-token');
  const locale = authBtn.getAttribute('data-locale');
  let authWindow; // Reference to the popup window

  // Add click event to open the Twitter authentication popup
  authBtn.addEventListener('click', function () {
    // Open Twitter authentication in a popup window
    authWindow = window.open(`/${locale}/twitter/auth?state=${token}`, 'Twitter Auth', 'width=600,height=700');
  });

  // Subscribe to the Twitter channel using Action Cable
  consumer.subscriptions.create(
    { channel: "TwitterChannel", token: token },
    {
      connected() {
        console.log('Connected to Twitter channel with token:', token);
      },

      disconnected() {
        console.log('Disconnected from Twitter channel');
      },

      received(data) {
        console.log('Received data:', data);

        // Check for the close_window command and close the popup if it exists
        if (data.type === 'twitter_auth_complete' && data.event_user_token === token) {
          if (data.command === 'close_window' && authWindow) {
            console.log('Closing Twitter authentication window.');
            authWindow.close(); // Close the popup window

            // Update the UI after closing the window
            document.getElementById('twitter-auth-btn').style.display = 'none';
            document.getElementById('twitter-post-btn').style.display = 'flex';
            //document.getElementById('twitter-feedback').style.display = 'block';
            //document.getElementById('twitter-feedback').innerText = 'Twitter connected. Ready to post.';

            // Update the preview button to include the poster URL
            const previewBtn = document.getElementById('twitter-post-btn');
            if (data.poster_url) {
              previewBtn.setAttribute('data-poster-url', data.poster_url);
            } else {
              console.error('No poster URL received');
            }
          }
        }
      }
    }
  );

  // Add event listener to show the preview modal when the post button is clicked
  document.getElementById('twitter-post-btn').addEventListener('click', function () {
    const posterUrl = this.getAttribute('data-poster-url');

    // Check if posterUrl is available before showing the modal
    if (posterUrl) {
      showPostPreviewModal(posterUrl, token, locale);
    } else {
      console.error('Poster URL is not set.');
    }
  });
});

function showPostPreviewModal(posterUrl, token, locale) {
  // Display the modal with the poster and post details
  const modalContent = `
    <div dir="ltr" class="modal" id="tpostPreviewModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Post Preview</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <img src="${posterUrl}" alt="Post Preview" style="width: 100%;">
            <p>I'll be attending the <strong>Riyadh Economic Forum 2024</strong>!<br>
            Join me by registering at <a href="http://registration.riyadhef.com/" target="_blank">http://registration.riyadhef.com/</a><br>
            📅 18-20 Nov at Riyadh Hilton Hotel, Riyadh, Saudi Arabia!</p>


          </div>
          <div class="modal-footer">
            <button id="t-publish-post-btn" type="button" class="btn btn-main" style="background: #28694e;color: white;">Publish</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  `;

  // Append modal to body and show it
  document.body.insertAdjacentHTML('beforeend', modalContent);
  $('#tpostPreviewModal').modal('show');

  // Handle publishing the post
  document.getElementById('t-publish-post-btn').addEventListener('click', function () {
    // Implement the publishing logic here
    this.disabled = true;
    this.innerText = 'Publishing...';

    fetch(`/${locale}/twitter/post_to_twitter?token=${token}`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'), // Correct way to fetch CSRF token
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          document.getElementById('t-publish-post-btn').innerText = 'Published';
        } else {
          document.getElementById('t-publish-post-btn').innerText = 'Failed to Publish';
        }
      })
      .catch(error => {
        document.getElementById('t-publish-post-btn').innerText = 'Error';
        console.error('Error:', error);
      });
  });

  // Cleanup the modal on close
  $('#tpostPreviewModal').on('hidden.bs.modal', function () {
    this.remove();
  });
}
